import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import YouTube from "react-youtube"
import Layout from "../components/layout"
import BlueDots from "../../static/images/support-blue-dots.svg"
import BlueDot from "../../static/images/support-blue-dot.svg"
import BlueCicle from "../../static/images/support-blue-circle.svg"
import BlueDots2 from "../../static/images/support-blue-dots-2.svg"


const VideoExample = "KtXHe1jC_Nw"
const VideoExample2 = "VkqSTEbqg-8"
const VideoExample3 = "xSlFTPOeKwU"

const Videos = () => (
  <Layout>
    <Helmet>
      <title>Video examples | IBDmate</title>
    </Helmet>
    <div className="layout-videos">
      <div className="blue-bg">
        <div className="content">
          <h1>Video examples</h1>
          <p>IBDmate is packed full of videos with <Link to="/experts/">IBD experts</Link> and <Link to="/patients">patients</Link>. Get a sneak peek of some of the videos below</p>
        </div>
        <div className="vectors-left">
          <img src={BlueDots} id="blue-dots" alt="" />
          <img src={BlueDot} id="blue-dot" alt="" />
        </div>
        <div className="vectors-right">
          <img src={BlueCicle} id="blue-circle" alt="" />
          <img src={BlueDots2} id="blue-dots-2" alt="" />
        </div>
      </div>
      <div className="wrapper">
      <YouTube videoId={VideoExample} containerClassName="video" />
      <YouTube videoId={VideoExample2} containerClassName="video" />
      <YouTube videoId={VideoExample3} containerClassName="video" />
      </div>
    </div>
  </Layout>
)

export default Videos
